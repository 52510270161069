import React from "react";
// import styled from "styled-components";
import tw, {styled} from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings.js";
import { SectionDescription } from "src/ui-treact/components/misc/Typography.js";

const Container = styled.div`
  ${tw`flex w-full flex-col items-center bg-gray-100 py-16 px-16 md:px-56 bg-cover`}
  background-image: url(${props=>props.background_image});
`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto`}

`;
const Subheading = styled(SubheadingBase)(tw`mb-4 uppercase tracking-widest font-normal text-primary-500`,
props => [
  props.text_on_left && tw`w-full text-left mx-4 px-2`,
]
);
const Heading = styled(SectionHeading)(tw`w-full font-medium`,
props => [
  props.text_on_left && tw`w-full text-left mx-4 px-2`,
]
);
const Description = styled(SectionDescription)(tw`w-full text-center text-primary-500`,
props => [
  props.text_on_left && tw`max-w-full w-full text-left mx-4 px-2`,
]
);

const HighlightedText = tw.span`text-primary-500`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`


  ${tw`flex flex-col sm:flex-row items-start justify-center sm:items-start text-center h-full mx-4 px-2 py-8`}
  ${(props)=>props.cards_on_left &&  tw`flex flex-col sm:flex-row items-start justify-start`}
 
  .imageContainer {
    ${tw`border text-center rounded-full p-1 mt-4 flex-shrink-0 `}
    img {
      ${tw`w-12 h-12 max-w-full`}
    }
  }

  .textContainer {
    ${tw` flex flex-col items-start justify-center sm:ml-4 `}
    ${(props)=>props.cards_on_left &&  tw`items-start justify-start`}
  }
  .title {
    ${tw`mt-4 tracking-wide font-medium text-lg leading-none text-black`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 text-sm font-normal text-secondary-100 leading-loose text-primary-500`}
  }
  .emailPhone {
    ${tw`p-16 text-sm font-normal text-secondary-100 leading-loose text-primary-200`}
}

`;

const DecoratorBlob = styled.div`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const PrimaryAction = styled.a`
${tw`mx-2 px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold rounded shadow transition duration-300 hover:no-underline`}
background-color:${
  props=>props.backgroundColor
};
color:${
  props=>props.textColor
};
`

export default ({data,page}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */
  let finalData= data?.schema_json;  
  let content= {
     ...finalData
}  
// console.log('finalData', finalData)

  return (
    <Container background_image={content.background_image}>
      <ThreeColumnContainer text_on_left={content.text_on_left}>
        {content.sub_heading && <Subheading text_on_left={content.text_on_left}>{content.sub_heading}</Subheading>}
        <Heading text_on_left={content.text_on_left}>{content.heading} <HighlightedText>{content.highlightedText}</HighlightedText></Heading>
        {content.description && <Description text_on_left={content.text_on_left}>{content.description}</Description>}
        <VerticalSpacer />
        {content.cards ? content.cards.map((card, i) => (
          <Column key={i}>
            <Card cards_on_left={content.cards_on_left}>
              {card.img_src ?  <span className="imageContainer">
                <img src={card.img_src} alt="" />                
              </span> : null}
              <span className="textContainer">
                <span className="title">{card.heading || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
                <span className="emailPhone">
                {card.email ? <p>{card.email}</p> : null}
                {card.phone_number ? <p>{card.phone_number}</p> : null}
                </span>

              </span>
            </Card>
          </Column>
        )) : null}
      </ThreeColumnContainer>
      { content.button ? <PrimaryAction
        href={content.button?.href}
        backgroundColor={content.button?.backgroundColor}
        textColor={content.button?.textColor}
        >
          {content.button?.text}
      </PrimaryAction> : null }
      
      <DecoratorBlob />
    </Container>
  );
};
